import React from "react"
import { Link } from "gatsby"
import Image from "./imageComponent.js"
import * as waitingSectionStyles from "./waitingSection.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default function WaitingSection(props) {
  return (
    <div className={waitingSectionStyles.waitingContainer}>
      <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
        {props.servicePage ? (
          <h2>WHILE YOU WAIT FOR YOUR {props.serviceTitle.toUpperCase()}</h2>
        ) : (
          <h2>WHILE YOU WAIT</h2>
        )}
      </ScrollAnimation>
      <div className={waitingSectionStyles.waitingGrid}>
        <Link to="/ourlounge/">
          <ScrollAnimation
            animateIn="animate__fadeInLeft"
            animateOnce={true}
            duration={0.5}
          >
            <WaitingBtn
              title="Relax In Our Lounge"
              fileName="lounge.jpg"
              alt="Tintex's waiting area/lounge"
              text="Whilst we take care of your vehicle you can relax yourself on our comfy sofas..."
            />
          </ScrollAnimation>
        </Link>
        <Link to="/portsmouth/">
          <ScrollAnimation
            animateIn="animate__fadeInRight"
            animateOnce={true}
            duration={0.5}
          >
            <WaitingBtn
              title="Visit Portsmouth"
              fileName="portsmouth.jpg"
              alt="Aerial photo of Portsmouth city"
              text="Portsmouth is a dynamic and vibrant waterfront city packed with great reasons..."
            />
          </ScrollAnimation>
        </Link>
      </div>
    </div>
  )
}

class WaitingBtn extends React.Component {
  render() {
    return (
      <div className={waitingSectionStyles.waitingBtn}>
        <Image
          imgClass={waitingSectionStyles.waitingBtnImg}
          alt={this.props.alt}
          fileName={this.props.fileName}
          animated={false}
        />
        <div className={waitingSectionStyles.waitingeBtnEffect}></div>
        <div className={waitingSectionStyles.waitingBtnTextBG}>
          <span className={waitingSectionStyles.waitingBtnTitle}>
            {this.props.title}
          </span>
          <p className={waitingSectionStyles.waitingBtnText}>
            {this.props.text}
          </p>
        </div>
      </div>
    )
  }
}
